import React, { Component } from "react"
import NewLayout from '../layout/newLayout'
import styles from "../styles/scss/pages/llho.module.scss"
import stories from "./llho.json"
import Modal from 'react-responsive-modal';
import Title from '../components/title';
import StoryBlock from '../components/storyBlock';
import { find as _find } from "lodash"
import { priceSpiderRebind } from "../services/util"
import Helmet from "react-helmet"
import Imgix from "react-imgix";

export default class LLHO extends Component {

    state = { modal: false, data: undefined }

    getProducts(skus) {
        return skus.map(sku => {
            return _find(this.props.pageContext.products, p => {
                return p.skus.indexOf(sku) > -1
            })
        })
    }

    handleOpenModal(e) {
        this.setState({ modal: true, data: e }, () => {
            priceSpiderRebind()
        })
    }

    handleNextStoryClick() {
        const { data } = this.state
        let newData
        if (data.id === 10) {
            newData = stories.find(e => e.id === 1)
        } else {
            newData = stories.find(e => e.id === data.id + 1)
        }
        //
        this.setState({ data: newData }, function () {
            // TODO: Scroll to top of modal?
            priceSpiderRebind()
        })
    }

    componentDidMount() {
        const juicerscript = document.createElement("script")
        juicerscript.src = "https://assets.juicer.io/embed.js"
        juicerscript.async = true

        document.body.appendChild(juicerscript)

        const pricespiderscript = document.createElement("script")
        pricespiderscript.src = "//cdn.pricespider.com/1/lib/ps-widget.js"
        pricespiderscript.async = true

        document.body.appendChild(pricespiderscript)

        stories.map(s => {
            s.products = this.getProducts(s.products)
        })

    }

    render() {
        const { modal, data } = this.state;
        let page = this.props.pageContext.page
        return (
            <NewLayout>

                <Helmet>
                    <script src="https://assets.juicer.io/embed-no-jquery.js" type="text/javascript" defer></script>
                    <link rel="stylesheet" href="//assets.juicer.io/embed.css" media="all" type="text/css" />
                </Helmet>

                {page.seo.title && <h1 style={{ display: 'none' }}>{page.seo.title}</h1>}

                <div className={styles.body}>
                    <div className={styles.container}>
                        <span className={styles.contentText}>
                            <p>
                                <span className={styles.contentTitle}>For Those Who Live Life Hands On</span>
                                <sup style={{ top: '0' }}>TM</sup>
                            </p>
                            <p>WD-40 Brand is in the business of creating positive lasting memories. We are with the doers, builders, and makers even in the most extreme, cannot fail, situations to get the job done right. Our brand delivers the trust and performance needed to instill confidence at work, home, and play and empowers people to Live Life Hands On.</p>
                            <p> We are sharing real life stories of how DIYers, tradesmen, industrial workers, and sport professionals rely on WD-40 Brand product performance that ultimately allows them to Live Life Hands On.</p>
                        </span>
                        <Title
                            value="Explore All Our Stories Below"
                            alignLeft
                        />
                        <div className={styles.items}>
                            {stories.map((e, i) => (
                                <div
                                    key={i}
                                    className={styles.containerImage}
                                    onClick={this.handleOpenModal.bind(this, e)}
                                >
                                    <div className={styles.imageUser}>
                                        <Imgix
                                            sizes="100vw"
                                            src={`/img/llho/${e.image}`}
                                            htmlAttributes={{alt: ''}}
                                        />
                                    </div>
                                    <div className={styles.bodyClass}>
                                        <Title className={styles.titleName} >
                                            {e.name}
                                        </Title>
                                        <Title className={styles.titleDescription}>
                                            {e.title}
                                        </Title>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.separator} />

                        <Title
                            size={30}
                            value="#Livelifehandson"
                            className={styles.livelifeTitle}
                        />
                        <div className={styles.handsonItems}>
                            <link
                                href="https://assets.juicer.io/embed.css"
                                media="all"
                                rel="stylesheet"
                                type="text/css"
                            />
                            <ul
                                className="juicer-feed"
                                data-feed-id="livelifehandson"
                            ></ul>
                        </div>
                    </div>
                </div>
                {
                    modal &&
                    <Modal   
                        blockScroll={false}
                        open={modal}
                        onClose={() => this.setState({ modal: false })}
                        classNames={{ 
                            modal: styles.customModal,
                            closeIcon: styles.closeIcon,
                            closeButton: styles.closeButton,
                            overlay: styles.modalOverlay,
                            modalContainer: styles.modalContainer,
                        }}
                        center
                    >
                        {data && (
                            <StoryBlock
                                id="bar"
                                data={data}
                                onClickNext={this.handleNextStoryClick.bind(this)}
                            />
                        )}
                    </Modal>
                }

            </NewLayout>
        )
    }
}
